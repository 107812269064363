import React, { Fragment } from "react"
import { compose } from "recompose"
import LogoutButton from "../components/Logout"
import Layout from "../components/layout"
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../components/Session"
import PageTitleArea from "../components/Common/PageTitleArea"
import { Link } from "gatsby"

function LicenseInfo(props) {
  const user = props.user
  if (user.licenseID) {
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12 col-lg-12 card mb-3 mt-5 pt-2 pb-2">
            <h5 className="card-title">HASS 2023 License</h5>
            <p>License ID: {user.licenseID}</p>
            <p>Activation Password: {user.activationPassword}</p>
            <p>Number of Users: {user.numUsers}</p>
            {user['expirationDate'] && <p>Expiration Date: {user.expirationDate}</p>}
            <p>Current Version: HASS 2023</p>
            <div className="btn-box">
              <a
                className="default-btn"
                href="https://storage.googleapis.com/hrs-systems-public/downloads/HASS/Setup.exe"
              >
                <i className="bx bxs-download"></i>
                Download Now
                <span></span>
              </a>
            </div>
            <RenewButton user={user} product={"hass"}/>
          </div>
        </div>
      </Fragment>
    )
  }
  return null
}

function BetaLicenseInfo(props) {
  const user = props.user
  if (user.hasBetaAccess) {
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12 col-lg-12 card mb-3 mt-5 pt-2 pb-2">
            <h5 className="card-title">HASS 2023 Beta Download</h5>
            <p>Please send any feedback to <a href="mailto:hass@hrssystems.com">hass@hrssystems.com</a></p>

            <p>Current Version: HASS 2023 Release 1 Build 29</p>
            <div className="btn-box">
              <a
                className="default-btn"
                href="https://storage.googleapis.com/hrs-systems-public/downloads/hass2023beta/Setup.exe"
              >
                <i className="bx bxs-download"></i>
                Download Now
                <span></span>
              </a>
            </div>
            <p>If you would like to revert back to version 2021, you can do so by reinstalling the version found in the HASS 2021 section above.</p>
          </div>
        </div>
      </Fragment>
    )
  }
  return null
}

function isLessThanYearAway(date) {
  let now = new Date()
  let yearFromNow = new Date(now);
  yearFromNow.setDate(now.getDate() + 365);
  let expirationDate = Date.parse(date)

  if (expirationDate < yearFromNow){
    return true
  }
  else return false
}

function RenewButton(props) {
  const user = props.user
  const product = props.product

  let licenseIdName = null
  let numUsersName = null
  let licenseExpiresName = null
  switch (product) {
    case "hass":
      licenseIdName = "licenseID"
      numUsersName = "numUsers"
      licenseExpiresName = "expirationDate"
      break
    case "hassHouse":
      licenseIdName = "hassHouseLicenseID"
      numUsersName = "hassHouseNumUsers"
      licenseExpiresName = "hassHouseExpirationDate"
      break
    case "coosa":
      licenseIdName = "coosaLicenseID"
      numUsersName = "coosaNumUsers"
      licenseExpiresName = "coosaExpirationDate"
      break
    case "oldHassEnglish":
      break
    case "oldHassMetric":
      break
    case "oldHassSpanish":
      break
  }

  if (user[licenseIdName] && user[numUsersName] && isLessThanYearAway(user[licenseExpiresName])) {
    return (
      <Fragment>
        <div className="btn-box">
          <Link
            className="default-btn"
            to="/checkout-page-renew/"
            state={{ userInfo: user, product: product }}
          >
            <i className="bx bxs-credit-card"></i>
            Renew License
            <span></span>
          </Link>
        </div>
      </Fragment>
    )
  }
  return null
}

function EnglishLicenseInfo(props) {
  const user = props.user
  if (user.oldEnglishLicenseID) {
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12 col-lg-12 card mb-3 mt-5 pt-2 pb-2">
            <h5 className="card-title">HASS 8.8R5 English License</h5>
            <p>License ID: {user.oldEnglishLicenseID}</p>
            <p>Activation Password: {user.oldEnglishActivationPassword}</p>
            <p>Number of Users: {user.oldNumUsers}</p>
            <div className="btn-box">
              <a
                className="default-btn"
                href="https://storage.googleapis.com/hrs-systems-public/downloads/HASS88/English/Setupe.exe"
              >
                <i className="bx bxs-download"></i>
                Download Now
                <span></span>
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
  return null
}

function MetricLicenseInfo(props) {
  const user = props.user
  if (user.oldMetricLicenseID) {
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12 col-lg-12 card mb-3 mt-5 pt-2 pb-2">
            <h5 className="card-title">HASS 8.8R5 Metric License</h5>
            <p>License ID: {user.oldMetricLicenseID}</p>
            <p>Activation Password: {user.oldMetricActivationPassword}</p>
            <p>Number of Users: {user.oldNumUsers}</p>
            <div className="btn-box">
              <a
                className="default-btn"
                href="https://storage.googleapis.com/hrs-systems-public/downloads/HASS88/Metric/Setupm.exe"
              >
                <i className="bx bxs-download"></i>
                Download Now
                <span></span>
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
  return null
}

function SpanishLicenseInfo(props) {
  const user = props.user
  if (user.oldSpanishLicenseID) {
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12 col-lg-12 card mb-3 mt-5 pt-2 pb-2">
            <h5 className="card-title">HASS 8.8R5 Spanish License</h5>
            <p>License ID: {user.oldSpanishLicenseID}</p>
            <p>Activation Password: {user.oldSpanishActivationPassword}</p>
            <p>Number of Users: {user.oldNumUsers}</p>
            <div className="btn-box">
              <a
                className="default-btn"
                href="https://storage.googleapis.com/hrs-systems-public/downloads/HASS88/Spanish/Setups.exe"
              >
                <i className="bx bxs-download"></i>
                Download Now
                <span></span>
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
  return null
}

function HassHouseLicenseInfo(props) {
  const user = props.user
  if (user.hassHouseLicenseID) {
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12 col-lg-12 card mb-3 mt-5 pt-2 pb-2">
            <h5 className="card-title">HASS House License</h5>
            <p>License ID: {user.hassHouseLicenseID}</p>
            <p>Activation Password: {user.hassHouseActivationPassword}</p>
            <p>Number of Users: {user.hassHouseNumUsers}</p>
            {user['hassHouseExpirationDate'] && <p>Expiration Date: {user.hassHouseExpirationDate}</p>}
            <div className="btn-box">
              <a
                className="default-btn"
                href="https://storage.googleapis.com/hrs-systems-public/downloads/hh2023/Setup.exe"
              >
                <i className="bx bxs-download"></i>
                Download Now
                <span></span>
              </a>
            </div>
            <RenewButton user={user} product={"hassHouse"}/>
          </div>
        </div>
      </Fragment>
    )
  }
  return null
}

function CoosaLicenseInfo(props) {
  const user = props.user
  if (user.coosaLicenseID) {
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12 col-lg-12 card mb-3 mt-5 pt-2 pb-2">
            <h5 className="card-title">COOSA License</h5>
            <p>License ID: {user.coosaLicenseID}</p>
            <p>Activation Password: {user.coosaActivationPassword}</p>
            <p>Number of Users: {user.coosaNumUsers}</p>
            {user['coosaExpirationDate'] && <p>Expiration Date: {user.coosaExpirationDate}</p>}
            <div className="btn-box">
              <a
                className="default-btn"
                href="https://storage.googleapis.com/hrs-systems-public/downloads/cs2020/Setup.exe"
              >
                <i className="bx bxs-download"></i>
                Download Now
                <span></span>
              </a>
            </div>
            <RenewButton user={user} product={"coosa"}/>
          </div>
        </div>
      </Fragment>
    )
  }
  return null
}


function Hass88DownloadInfo(props) {
  const user = props.user
  if (user.canDownload88) {
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12 col-lg-12 card mb-3 mt-5 pt-2 pb-2">
            <h5 className="card-title">HASS 8.8 Download</h5>
            <p>
              If you wish to stick with the old version of HASS you may generate
              your licenses here:
            </p>
            <div className="btn-box">
              <Link to="/generate-88-licenses">
                <div className="default-btn">
                  <i className="bx bxs-hot"></i>
                  Generate 8.8 Licenses
                  <span></span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
  return null
}

const AccountPageBase = () => (
  <Fragment>
    <AuthUserContext.Consumer>
      {authUser => (
        <div>
          <div className="row">
            <div className="col-md-12 col-lg-12 card mt-5 pt-2 pb-2">
              <h5 className="card-title">Account Information</h5>
              <p>Account: {authUser.email}</p>
              <p>Company: {authUser.company}</p>
            </div>
          </div>

          <LicenseInfo user={authUser} />
          {/*<BetaLicenseInfo user={authUser} />*/}
          <HassHouseLicenseInfo user={authUser} />
          <CoosaLicenseInfo user={authUser} />

          <EnglishLicenseInfo user={authUser} />
          <MetricLicenseInfo user={authUser} />
          <SpanishLicenseInfo user={authUser} />
          <Hass88DownloadInfo user={authUser} />

          {/*<PasswordChangeForm />*/}
          {/*<LoginManagement authUser={authUser} />*/}
        </div>
      )}
    </AuthUserContext.Consumer>
    <LogoutButton />
  </Fragment>
)

const condition = authUser => !!authUser

const AccountPage = compose(
  withEmailVerification,
  withAuthorization(condition, "account")
)(AccountPageBase)

export default () => (
  <Layout>
    <PageTitleArea
      pageTitle="Account Information"
      // pageDescription="High value, higher power."
    />
    <section className="free-trial-area pb-100">
      <div className="container">
        <div className="free-trial-content">
          <AccountPage />
        </div>
      </div>
    </section>
  </Layout>
)
